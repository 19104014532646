import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, SectionTitle, ContactBlock, CtaButton, ImageLeft, ContactForm, PhoneLink } from "../styles/styles"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/small-banner"

import image from "../images/contact.jpg"

const Contact = () => {

  const data = useStaticQuery(graphql`
  query ContactData {
    image: file(relativePath: {eq: "sample-image.jpg"}) {  
      id
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <Layout>
      <SEO title="Contact" />
      <SmallBanner image={image} position="center top">
        <Container style={{maxWidth:'1440px'}}>
          <h1>Contact Us</h1>
        </Container>
      </SmallBanner>
      <Container style={{maxWidth:'1440px'}}>
        <ContactBlock>
          <SectionTitle>Talk to us and find out how we can help</SectionTitle>
          {/* <div style={{display:'flex',alignItems:'center',marginBottom:'25px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="50" style={{marginRight:'25px',filter:'drop-shadow(3px 3px 0 rgba(0, 255, 255, 0.3))',fill:'#631b9a'}}>
              <path d="M511.95 412.553c-.714-19.805-8.964-38.287-23.229-52.042-27.931-26.933-51.333-42.481-73.647-48.929-30.745-8.886-59.225-.806-84.641 24.016l-.114.113-27.04 26.838c-16.899-9.526-49.759-30.442-85.261-65.944l-2.632-2.63c-35.508-35.507-56.423-68.373-65.94-85.253l26.84-27.041.114-.115c24.821-25.414 32.901-53.892 24.016-84.641-6.449-22.313-21.996-45.715-48.93-73.646C137.732 9.016 119.251.766 99.446.052 79.629-.663 60.613 6.235 45.865 19.473l-.574.516a20.9 20.9 0 00-.78.739C15.152 50.087-.238 91.188.003 139.589c.411 82.222 45.6 176.25 120.879 251.528 14.195 14.195 30.328 28.243 47.95 41.755 8.765 6.72 21.319 5.064 28.04-3.702 6.722-8.766 5.064-21.32-3.702-28.041-16.235-12.449-31.04-25.333-44.004-38.294C81.17 294.839 40.361 211.309 40.002 139.39c-.185-37.276 11.028-68.389 32.431-90.015l.153-.138a35.977 35.977 0 0150.108 1.808c51.779 53.698 48.03 79.049 25.15 102.511l-37.074 37.352a20.003 20.003 0 00-4.076 22.226c.941 2.114 23.71 52.427 80.415 109.132l2.633 2.629c56.698 56.699 107.012 79.467 109.126 80.408a19.998 19.998 0 0022.225-4.075l37.353-37.075c23.465-22.881 48.816-26.628 102.511 25.151a35.976 35.976 0 011.811 50.105l-.142.157c-21.449 21.229-52.231 32.433-89.101 32.433-.304 0-.608 0-.913-.002-29.471-.147-63.598-8.226-98.689-23.362-10.14-4.376-21.911.3-26.285 10.443-4.375 10.143.3 21.911 10.443 26.286 40.562 17.496 79.028 26.456 114.332 26.633l1.122.002c47.914 0 88.607-15.379 117.739-44.51.253-.254.499-.513.738-.78l.519-.577c13.237-14.747 20.134-33.775 19.419-53.579z"/>
            </svg>
            <PhoneLink href="tel:"></PhoneLink>
          </div> */}
          <div style={{width:'100%'}}>
            
            <ImageLeft style={{marginTop:'50px'}} mobNoImage>
  
              <div style={{minWidth:'50%'}}>
                <Img fluid={data.image.childImageSharp.fluid} style={{marginRight:'50px'}} />
              </div>
  
              <div style={{minWidth:'50%'}}>
                <ContactForm name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="contact" />
                  <div>
                    <input type="text" name="name" placeholder="Name" />
                    <input type="text" name="email" placeholder="Email" />
                  </div>
                  <div>
                    <textarea placeholder="Message" name="message" />
                  </div>
                  <button type="submit">Send message</button>
                </ContactForm>
              </div>            
  
            </ImageLeft>
          </div>
        </ContactBlock>
        
      </Container>
    </Layout>
  )
} 

export default Contact
